<template>
  <div class="container">
    <div class="row mb-5">
      <div class="col text-center">
        <h2 class="header-center">Hubungi Kami</h2>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-md-10 col-lg-8">
        <div class="row">
          <div class="col">
            <div class="mb-3">
              <input
                type="text"
                class="form-control"
                id="exampleFormControlInput1"
                placeholder="Nama Lengkap"
              />
            </div>
          </div>
          <div class="col">
            <div class="mb-3">
              <input
                type="email"
                class="form-control"
                id="exampleFormControlInput1"
                placeholder="Email"
              />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="mb-3">
            <textarea
              class="form-control"
              id="exampleFormControlTextarea1"
              rows="5"
              placeholder="Isi pesan ..."
            ></textarea>
          </div>
        </div>

        <div class="d-flex justify-content-center">
          <button type="button" class="btn mt-2">
            <i class="bi-envelope"></i> Kirim Pesan
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Hubungi",
};
</script>

<style lang="scss" scoped>
@import "@/scss/_variable.scss";

input,
textarea {
  background-color: transparent;
  border: 1px solid #bdbdbd;
  border-radius: 8px;
  color: $textColor;
}
button {
  background-color: $primaryColor;
  border-radius: 8px;
  color: #ffffff;

  &:hover {
    color: #ffffff;
  }
}
</style>
